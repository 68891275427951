// import logo from './logo.svg';
import './App.css';
import Complaint from './components/complaint';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';	
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <Complaint/>
  );
}

export default App;
