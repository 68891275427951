import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import Select, { components } from 'react-select'
import { ToastContainer } from "react-toastify";
import { IoMdArrowDropdown } from 'react-icons/io';
import * as yup from 'yup'
import './complaint.css'
import axiosInst from '../axiosConfig/axiosInstance'
import SweetAlert from 'react-bootstrap-sweetalert';
export default function Complaint() {

    const [brances, setBranches] = useState([])
    const [selected, setSelectedOption] = useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [showAlertWrong, setshowAlertWrong] = React.useState(false);

    const formScyma = yup.object().shape({
        userName: yup.string().min(2).required(),
        userMobile: yup.string().required(),
        branch: yup.string().required(),
        complaint: yup.string().min(3).required()
    })
    const [Complaint, setComplaint] = useState({
        userName: "",
        userMobile: "",
        complaint: "",
        branch: "",
    })
    const [error, setError] = useState({
        userNameErr: false,
        userMobileErr: false,
        complaintErr: false,
        branchErr: false,
    })

    useEffect(() => {
        axiosInst.get(`/branch/getAllBranches`).then((res) => {
            console.log(res.data)
            setBranches(res.data)
            console.log(brances)
        }).catch((err) => {
            console.log(err)
        })
        
    }, [])

    function updateForm(event) {
        setComplaint({ ...Complaint, [event.target.name]: event.target.value })

        switch (event.target.name) {
            case "userName": setError({ ...error, userNameErr: (event.target.value.length === 0) ? "الاسـم مطلوب" : "" })
                break;
            case "userMobile": setError({ ...error, userMobileErr: (event.target.value.length === 0) ? "رقم الحوال مطلوب  " : "" })
                break;
            case "complaint":
                setError({ ...error, complaintErr: (event.target.value.length === 0) ? "برجاء كتابة الملاحظة " : "" })
                break;
            case "branch":
                setError({ ...error, branchErr: (event.target.value === "") ? "Branch is required " : "" })
                break;
            default: break;
        }
        // console.log(brances)
        // console.log(Complaint)
        // console.log(event.target.value.length)
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '17px', // set the border radius to 8 pixels
            border: state.isFocused ? '0px solid white' : '0px solid white ', // customize the border style
            // backgroundColor: state.isSelected ? 'gray' : 'lightgray',
        }),

        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? 'rgb(108,36,52)' : 'white',
                color: isFocused ? 'white' : 'black',

            };
        }
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <IoMdArrowDropdown className='mainColor fs-2' size={30} />
            </components.DropdownIndicator>
        );
    }
    const handleChange = (selectedOption) => {
        let userSelect = selectedOption.value
        setSelectedOption(userSelect);
        console.log(`Option selected:`, selected);
        console.log(`Option selected:`, selectedOption.label);

        setComplaint({ ...Complaint, branch: selectedOption.value })
    };
    async function handleSubmit(e) {
        e.preventDefault();
        const isFormValide = await formScyma.isValid(Complaint)
        console.log(isFormValide)
        if (isFormValide) {
            axiosInst.post(`/complaint`, Complaint).then((res) => {
                console.log(res)
                setShowAlert(true)
                setComplaint({
                    userName: "",
                    userMobile: "",
                    branch: selected,
                    complaint: ""
                });
                // console.log(selected ,Complaint)

            }).catch((err) => {
                console.log(err.message)
                setshowAlertWrong(true)

            })
        }
        else {
            setshowAlertWrong(true)
        }

    }
    const handleConfirm = () => {
        setShowAlert(false);
        // Handle confirm logic here
    };

    const handleCancel = () => {
        setShowAlert(false);
        // Handle cancel logic here
    };
    const handleConfirmWrongMessage = () => {
        setshowAlertWrong(false);
        // Handle confirm logic here
    };

    const handleCancelWrongMessage = () => {
        setshowAlertWrong(false);
        // Handle cancel logic here
    };
    return (

        <div className="container-fluid contain pb-3">
            {/* <ToastContainer autoClose={3000} /> */}
            <SweetAlert
                success
                title="  تم ارسال الطلب بنجاح
            "
                show={showAlert}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                timeout={2000}
            >
            </SweetAlert>
            <SweetAlert
                danger

                show={showAlertWrong}

                title="حدث خطأ ما.. برجاء ملأ جميع الحقول واعادة المحاولة "
                onConfirm={handleConfirmWrongMessage}
                onCancel={handleCancelWrongMessage}
                timeout={2000}
            >

            </SweetAlert>

            <div className="container mainContainer  ">
                {/* <h1>hello</h1> */}
                <div className="row d-flex justify-content-center align-items-center flex-column  flex-nowrap content ">
                    <div className="col-5 align-self-start mb-2">
                        <img className='w-100' src={logo} alt="logo " />
                    </div>

                    <div className="col-12 pb-3  shadow  customrounded formContainer">
                        <h5 className=" note">رفع ملاحظة الي الادارة</h5>
                        <hr className="bg-warning border-warning"></hr>
                        <p class="fw-light fs-5 welcomText">عميلنا العزيز.. بإمكانك رفع كافة ملاحظاتك واستفساراتك مباشرة إلي إدارة شركة الإنجاز من خلال هذا الرابط، اكمل كافة البيانات بالأسفل واضغط ارسال.</p>
                        <form onSubmit={(e) => { handleSubmit(e) }}>
                            {/* <div className='row p-0 m-0'> */}
                            <div class=" form-group">
                                <i class="bi bi-circle-fill mainColor ps-3"></i>
                                <label for="exampleFormControlSelect1">اسم الفرع</label>
                                <Select name="branch" className="rounded-pill" styles={customStyles} placeholder={null} onChange={handleChange} options={brances.map(branch => ({ label: branch.title, value: branch._id }))}
                                    components={{ DropdownIndicator }}
                                />


                            </div>
                            <div class=" form-group">
                                <i class="bi bi-circle-fill mainColor ps-3"></i>
                                <label for="exampleFormControlInput1"> اسم العميل </label>
                                <input type="text" class="form-control rounded-pill" id="exampleFormControlInput1"
                                    name='userName' value={Complaint.userName} onChange={(e) => { updateForm(e) }} />
                                <p className="text-danger font-weight-light">{error.userNameErr}</p>
                            </div>
                            {/* </div> */}
                            {/* <div className='row p-0 m-0'> */}
                            <div class=" form-group">
                                <i class="bi bi-circle-fill mainColor ps-3"></i>
                                <label for="exampleFormControlInput1">رقم الجوال</label>
                                <input type="text" class="form-control rounded-pill" id="exampleFormControlInput1"
                                    name='userMobile' value={Complaint.userMobile} onChange={(e) => { updateForm(e) }} />
                                <p className='text-danger font-weight-light'>{error.userMobileErr}</p>
                            </div>

                            <div class=" form-group">
                                <i class="bi bi-circle-fill mainColor ps-3"></i>
                                <label for="exampleFormControlTextarea1">بيان الملاحظة</label>
                                <textarea class="form-control customrounded" id="exampleFormControlTextarea1" rows="4"
                                    name='complaint' value={Complaint.complaint} onChange={(e) => { updateForm(e) }}
                                ></textarea>
                                <p className='text-danger font-weight-light'>{error.complaintErr}</p>
                            </div>
                            {/* </div> */}
                            <p className="welcomText">  كافة ملاحظاتك واستفساراتك هي مركز اهتمامنا، وسيتم التواصل معك خلال 24 ساعة من رفع الملاحظة.</p>
                            <div className='d-flex justify-content-center align-items-center text-center'>
                                <button type="submit" className="btn btn-lg btnsubmit rounded-pill w-100  ">ارســــــــال</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

